/*
* @Author: 曹俊杰
* @Date: 2024-09-23 11:32:12
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-10-12 15:26:39
*/执行
<template>
  <div class="box">
    <div class="title">
      <div>权益执行</div>
      <el-button class="btn" type="primary" size="mini" @click="$router.push({name:'equityFrom'})">创建权益执行</el-button>
    </div>
    <equity-search-form :searchForm.sync="listParams" @submit="getList" />
    <div class="main">
      <equityList :list="list" />
      <hdq-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getList" />
    </div>
  </div>
</template>

<script>
import equitySearchForm from "./components/equitySearchForm.vue"
import equityList from "./components/equityList.vue"
import hdqPagination from "@/components/hdqPagination.vue"
import list from "./mixins/list"
export default {
  mixins: [list],
  components: {
    equitySearchForm,
    hdqPagination,
    equityList
  },

}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/listStyle.scss";

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btn {
    width: 108px;
    height: 32px;
  }
}
</style>